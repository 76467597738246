<template>
    <div class="mainWrap">

        <div class="mainBar" :style="{
            // backgroundImage: 'url(' + (winW > 1920 ? bannerBg2x : bannerBg) + ')',
            backgroundImage: 'url(' + (bannerBg) + ')',
        }">

            <headerWX class="headerWX"></headerWX>
            <noticeWX></noticeWX>

            <div class="hallWrap">
                <div class="top">
                    <img src="@/assets/imgs/Exchange/head_title.png" alt="">
                </div>
                <!-- 用户信息 -->
                <div class="describe">
                    <div>
                        大区：<div>{{ userInfo.gameArea == '' ? '暂无' : userInfo.gameArea }}</div>
                    </div>
                    <div>
                        游戏昵称：<div>{{ userInfo.usernick == '' ? '暂无' : userInfo.usernick }}</div>
                    </div>
                </div>
                <!-- 兑换按钮 -->
                <div class="center">
                    <div class="center_boby">
                        <div>
                            <div>兑换码：</div> <input type="text" v-model="center" placeholder="请输入兑换码">
                        </div>

                    </div>
                    <div class="center_btn" @click="submit()">

                    </div>
                </div>
                <!-- 提示信息 -->
                <div class="bottom">
                    <div>
                        <p>1.每种兑换码每个账号仅限使用一次。</p>
                        <p>2.兑换成功后请登录游戏，打开纸条箱领取礼包奖励服饰以及宠物。</p>
                        <p>3.通过礼包获取幸运钥匙、泡点、包装纸、精品服饰兑换券后请与官网账户中查收。</p>
                        <p>4.兑换码逾期不补，获取后请及时兑换。</p>

                    </div>
                </div>
            </div>

            <footerWX></footerWX>
        </div>
        <!-- 二次确认弹窗 -->
        <div id="mask" v-if="ispopUp">
            <div
                style="position: fixed; top: 13%;left: 35%;border-radius: 10px; width: 30vw; height: 70vh;min-width: 600px; padding: 5px;background: linear-gradient(#D7B7FF, #F9F8FF); z-index: 9999;">
                <popUp @ispopUp="TzpopUp" propShow=3 :DBF_goShop=DBF_goShop></popUp>
            </div>
        </div>

    </div>
</template>

<script>
// import menuWX from '@/components/Menu'
import axios from "axios";
import Cookies from "js-cookie";
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
import popUp from "@/components/pop_up";
import noticeWX from '@/views/home/notice'

export default {
    name: 'SixOneEight',
    components: {
        // menuWX,
        noticeWX,
        headerWX,
        footerWX,
        popUp,
    },
    data() {
        return {
            bannerBg: require("@/assets/imgs/Exchange/bannerBg.png"),
            // 每日任务获取倒计时判断
            dailyTask: 60,
            ispopUp: false,

            DBF_goShop: '',
            userInfo: {
                verifyInfo: {},
                vipInfo: {},
                wallteInfo: {},
            },            // 用户信息
            center: '',
        }
    },
    created() {
        // this.getHallFameData()
    },
    mounted() {
        document.documentElement.scrollTop = 0
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
    methods: {

        // 兑换码提交

        submit() {
            if (this.center == '') {
                this.$message.error('请输入兑换码')
                return
            } else {
                axios({
                    method: 'post',
                    url: '/activity/giftExchange',
                    headers: {
                        token: Cookies.get('wx_token'),
                    },
                    data: {
                        code: this.center,
                        source: this.userInfo.source,
                        userId: Cookies.get('wx_userId'),      // userId
                        platForm: 'web',
                    },
                }).then((res) => {
                    if (res.data.state == 200) {
                        // console.log(res.data);
                        this.center = ''
                        this.$message.success(res.data.message)

                    } else {
                        this.center = ''
                        this.$message.error(res.data.message)
                        // ({
                        //     type: 'error',
                        //     message: res.data.message,
                        //     duration: 3000
                        // })
                    }
                }).catch(err => {
                    this.$message.error(err.message)
                })

            }



        }
    }
}
</script>

<style lang="less" scoped>
.headerWX {
    margin: 0;

    /deep/ .header {
        background: transparent;
    }
}

#mask {
    width: 100%;
    height: 100%;
    // opacity: 0.6;
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 999;
}

.mainWrap {
    min-width: 1200px;
    min-height: 100vh;
    background: linear-gradient(180deg, #300055 0%, #17182a 100%);
    // background-image: url('../../assets/imgs/DragonBoatFestival/bannerBg.png');

    .mainBar {
        color: #333333;
        background-size: 100%;
        // background-position: top center;
        // background-repeat: no-repeat;
        min-height: 100vh;
        overflow: hidden;
        background-size: 100% 82%;

    }
}

.hallWrap {
    // height: 1131px;
    min-height: 100vh;
    width: 70%;
    // background-color: #3700ff85;
    margin: auto;
    position: relative;

    .top {
        text-align: center;
        margin: 20px 0;
    }

    .describe {
        width: 50%;
        height: 50px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        // margin: 20px 0;

        div {
            width: 50%;
            font-size: 20px;
            display: flex;
            align-items: center;

            div {
                margin-left: 10px;
                font-weight: bold;
                font-family: MingLiU, PingFang SC;
                font-size: 25px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: none;
                -webkit-text-stroke: 1px #EA0000;
                padding-bottom: 5px;
                white-space: nowrap;

            }
        }

    }

    .center {
        width: 60%;
        margin: auto;
        margin-bottom: 50px;

        .center_boby {
            height: 400px;
            background-image: url(../../assets/imgs/Exchange/exchange.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                height: 80px;

                div {
                    display: inline-block;
                    height: 80px;
                    color: #fff;
                    font-size: 30px;
                }

                input {
                    height: 50px;
                    width: 300px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #FFFFFF;
                    font-size: 30px;
                    padding-left: 20px;
                }
            }

        }

        .center_btn {
            width: 300px;
            height: 100px;
            background-image: url(../../assets/imgs/Exchange/btn.png);
            background-size: 100% 100%;
            margin: auto;
        }

    }

    .bottom {
        width: 60%;
        margin: auto;
        height: 500px;
        background: url(../../assets/imgs/Exchange/hint.png);
        background-size: 100% 100%;
        position: relative;

        div {
            height: 270px;
            width: 75%;
            margin: auto;
            position: absolute;
            bottom: 0;
            left: 90px;

            p {
                font-size: 20px;
                margin-top: 20px;
                line-height: 30px;
                color: #FFFFFF;

            }

        }
    }
}
</style>